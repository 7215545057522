<!-- /layouts/LayoutDefault.vue -->
<template>
  <main>
    <Nav/>
    <slot/>
    <Footer/>
    <s-modals-container/>
  </main>
</template>

<script setup>
import { useBaseLayout } from '/layouts/useBaseLayout'
import Footer from '/components/Footer.vue'
import Nav from '/components/Nav.vue'

const { SModalsContainer } = useBaseLayout()
</script>

<style type="scss">
@import './baseStyles.scss';
</style>
