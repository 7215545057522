import { watch } from 'vue';

export function useDynamicImageLoader(watchPropRef, dataAttrRef) {
  watch(watchPropRef, async (newVal, oldVal) => {
    if (!newVal || newVal === oldVal) {
      dataAttrRef.value = '';
      return;
    }

    // If it's a data URL or absolute URL, use directly
    if (newVal.startsWith('data:') || newVal.startsWith('http') || newVal.startsWith('/')) {
      dataAttrRef.value = newVal;
      return;
    }

    try {
      // For local image assets
      const [imageName] = newVal.split('.');
      const module = await (/* @vite-ignore */ import(`../assets/images/${imageName}.svg?url`));
      dataAttrRef.value = module?.default || '';
    } catch (error) {
      console.error(`Failed to load image: ${newVal}`, error);
      dataAttrRef.value = '';
    }
  }, { immediate: true });
}
