<template>
  <div :class="[isHidden ? 'footer-hidden' : 'footer']">
    <section>

      <s-button class="button-support" btn-link="https://www.seedr.cc/faq">{{ $t('footer.support-link') }}</s-button>
      <a class="footer-link" href="/about">{{ $t('footer.about-link') }}</a>
      <a href="https://www.seedr.cc/faq/" class="footer-link">{{ $t('footer.faq-link') }}</a>
      <a href="https://help.seedr.cc/hc/en-us/requests/new" class="footer-link">{{ $t('footer.contact-us-link') }}</a>
      <a class="footer-link" href="/termsofuse">{{ $t('footer.terms-link') }}</a>
      <a class="footer-link" href="/privacypolicy">{{ $t('footer.Privacy-link') }}</a>
<!--      <a class="footer-link" href="/dmca">{{ $t('footer.dmca-link') }}</a>-->
    </section>

    <section>
      <p class="footer-copyrights">&copy; {{ $t('footer.date') }} | {{ $t('footer.rights') }}</p>
    </section>
  </div>
</template>

<script>
import SButton from "../components/SButton.vue";

import { usePageContext } from "vike-vue/usePageContext"

export default {
  name: 'Footer',
  components: {
    SButton,
  },
  props: {
    msg: String,
  },
  data () {
    return {
    }
  },
  computed: {
    pageContext() {
      return usePageContext()
    },
    isHidden() {
      const hideNavPages = ['/payment', '/pulse'] // page paths where the top nav is hidden
      return hideNavPages.some(page => this.pageContext.urlPathname.startsWith(page));
    }
  }
}
</script>
