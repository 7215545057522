<template>
  <div class="s-modal-comp" :class="modalTypeClass" @click.self="$emit('clickLeave')" >

    <!-- Parent - All types-->
    <div class="s-modal-parent">

      <section v-if="hasElement('title-login')"  class="gradient-top-border"></section>



      <section v-if="hasElement('title')" class="s-modal-title">


        <!-- Icon - Modal Type  -->
        <div class="s-icon-badge-group">
          <s-icon class="modal-type-icon" :icon-font="modalTitleIcon" :icon-image="modalTitleImage"></s-icon>
        </div>

        <!-- Title -->
        <span>{{modalTitle}}</span>

        <!-- Icon - Close / back  -->
        <s-icon v-if="hasElement('titleCloseButton')" class="close-modal-icon s-hvr-circle-blue" :roundsquare="true" :tooltip="$t('actions.close')" icon-font="s-icon-x-thick"  @click="$emit('clickClose')"/>
        <s-icon v-if="hasElement('titleBackButton')" class="close-modal-icon s-hvr-circle-blue" :circle="true" :tooltip="$t('actions.tooltip.back-tooltip')" icon-font="s-icon-back"  @click="$emit('clickBack')"></s-icon>

      </section>
      <s-icon v-if="hasElement('title-login')"  class="icon-logo s-no-pointer" circle :icon-image="'Logo_SVG.svg'"/>

      <section   class="s-modal-iframe">
        <iframe v-if="hasElement('iframe-login')" id="login-frame" :src="loginUrl" name="login-frame" data-login=""></iframe>

      </section>



      <section v-if="hasElement('actions')"  class="s-modal-actions" :class="[{'s-show-pc-xs' : hasElement('actionsMobile')}] ">
        <s-button v-if="hasElement('actionsClose')" class="s-button-select secondary" icon-font="s-icon-x-thick" @click="$emit('clickClose')">{{$t('actions.close')}}</s-button>
        <s-button v-if="hasElement('actionsBack')" class="s-button-select secondary" icon-font="s-icon-back" @click="$emit('clickBack')">{{$t('actions.back')}}</s-button>
        <s-button v-if="hasElement('actionsCancel')" class="s-button-clear" @click="$emit('clickCancel')">{{$t('actions.cancel')}}</s-button>
        <s-button class="s-button-confirm modal-confirm-primary" @click="$emit('clickConfirm')">{{modalConfirmButton}}</s-button>
      </section>


    </div>

  </div>

</template>


<script>

import SButton from "./SButton.vue";
import SIcon from "./SIcon.vue";

export default {
  components: {
    SButton,
    SIcon,
  },
  props: {
    modalType: {
      type: String,
      default: ""
    },
  },
  data() {
    return {

      modalTitleMap: {
        'login' : '',
      },
      modalConfirmButtonMap: {
        'login': this.$t('actions.login'),
      },
      shownElements: {
        'login': ['title', 'title-login', 'titleCloseButton', 'iframe-login'],
      },

    };
  },
  emits: ['clickLeave', 'clickConfirm', 'clickAction', 'clickBack', 'clickClose', 'clickCancel'],
  computed: {
    modalTypeClass() {
      switch (this.modalType) {
        case "login":
          return "s-modal-login";
        default:
          return "";
      }
    },
    modalTitle() {
      return this.modalTitleMap[this.modalType]
    },
    modalTitleIcon() {
      switch (this.modalType) {
        case "login":
          return '';
        default:
          return "";
      }
    },
    modalTitleImage() {
      switch (this.modalType) {
        default:
          return "";
      }
    },
    modalConfirmButton() {
      return this.modalConfirmButtonMap[this.modalType]
    },
    loginUrl() {
      if(import.meta.env.VITE_V2 == "1") {
        return 'https://v2.seedr.cc/api/v0.1/auth/pages/login'
      } else {
        return 'https://www.seedr.cc/auth/pages/login'
      }
    }
  },
  methods: {
    hasElement(elementName) {
      return this.shownElements[this.modalType].includes(elementName)
    },

  },
};
</script>
