<template>
  <div v-if="isV2" id="nav-nav" class="nav-parent s-no-select" :class="[{'blog' : pageContext.urlPathname.includes('/pulse')},{'s-hide-mobile-l' : pageContext.urlPathname.includes('/pulse')},{'s-hide': navHidden }, {'links-hidden' : linksHidden}]">
    <nav>
      <a class="nav-homelogo" href="/">
        <img :src="navLogoWhtImg" alt="logo image" class="nav-logo s-hide-pc-m">
        <img :src="navLogoIconWhtImg" alt="logo image" class="nav-logo-icon s-show-pc-m">
      </a>

      <div class="coming-soon">
        <img :src="navV2Icon" alt="v2 icon">
        <span>Coming <br> Soon</span>
      </div>

      <div @click="linksHidden = !linksHidden" class="">
        <img  :src="linksHidden? navBtnImg : navBtnCloseImg" alt="nav_menu" class="nav-button-toggle">
      </div>

      <div @click="dropdownShown = !dropdownShown" class="nav-dropdown-parent s-hide" :class="[{'selected' : dropdownShown}]">

        <span class=""><s-icon icon-font="s-icon-language"/>{{languageSelected}}</span>
        <transition name="t-fade-expand">

          <div v-show="dropdownShown" class="nav-dropdown-child">
            <span @click="languageSelected = 'EN'" :class="[{'selected' : languageSelected == 'EN'}]">{{ $t('nav.language.en') }}</span>
            <span @click="languageSelected = 'ES'" :class="[{'selected' : languageSelected == 'ES'}]">{{ $t('nav.language.es') }}</span>
            <span @click="languageSelected = 'PT'" :class="[{'selected' : languageSelected == 'PT'}]">{{ $t('nav.language.pt') }}</span>
            <span @click="languageSelected = 'DE'" :class="[{'selected' : languageSelected == 'DE'}]">{{ $t('nav.language.de') }}</span>
            <span @click="languageSelected = 'FR'" :class="[{'selected' : languageSelected == 'FR'}]">{{ $t('nav.language.fr') }}</span>
            <span @click="languageSelected = 'HI'" :class="[{'selected' : languageSelected == 'HO'}]">{{ $t('nav.language.hi') }}</span>
          </div>
        </transition>
      </div>
      <hr class="s-hr-vertical-white s-hide-pc-xs s-hide">

      <a class="nav-link hvr-fade " href="/pricing">{{ $t('nav.pricing-link') }}</a>

      <div class="nav-products-parent nav-link hvr-fade"
         :class="[{'router-link-exact-active' : pageContext.urlPathname === '/products/video'}]">
        <span>{{ $t('nav.products-dropdown') }}</span>
        <div class="nav-products-child">
          <div>
<!--            <a href="/products/files"><img :src="iconProductFilesImg" alt="">{{ $t('nav.products-a') }}</a>-->
<!--            <a href="/products/cloud"><img :src="iconProductCloudImg" alt="">{{ $t('nav.products-b') }}</a>-->
<!--            <a href="/products/search"><img :src="iconProductSearchImg" alt="">{{ $t('nav.products-c') }}</a>-->
            <a :class="[{'router-link-exact-active' : pageContext.urlPathname === '/products/video'}]" href="/products/video"><img src="/images/products_video_icon.svg" alt="">{{ $t('nav.products-d') }}</a>
          </div>
        </div>
      </div>

      <a href="https://chrome.google.com/webstore/detail/seedr/abfimpkhacgimamjbiegeoponlepcbob" class="nav-link hvr-fade">
        {{ $t('nav.extension-link') }}
      </a>

      <span @click="navActions = 'login'" class="nav-link hvr-fade s-pointer">
        {{ $t('actions.login') }}
      </span>

      <s-button class="button-nav" btn-link="/signup">
        {{$t('actions.try-now')}}
      </s-button>

    </nav>
  </div>
  <div v-else  id="nav-nav" class="nav-parent v1 s-no-select" :class="[{'blog' : pageContext.urlPathname.includes('/pulse')}]">
    <nav>
      <a class="nav-homelogo" href="/">
        <img :src="logoMainImg" alt="Logo" class="nav-logo s-hide-mobile-s">
        <img :src="logoSvgImg" alt="Logo" class="nav-logo-icon s-show-mobile-s">
      </a>

      <div @click="linksHidden = !linksHidden" class="s-hide">
        <img  :src="linksHidden? navBtnImg : navBtnCloseImg" alt="nav_menu" class="nav-button-toggle">
      </div>

      <div @click="dropdownShown = !dropdownShown" class="nav-dropdown-parent s-hide" :class="[{'selected' : dropdownShown}]">

        <span class=""><s-icon icon-font="s-icon-language"/>{{languageSelected}}</span>
        <transition name="t-fade-expand">

          <div v-show="dropdownShown" class="nav-dropdown-child">
            <span @click="languageSelected = 'EN'" :class="[{'selected' : languageSelected == 'EN'}]">{{ $t('nav.language.en') }}</span>
            <span @click="languageSelected = 'ES'" :class="[{'selected' : languageSelected == 'ES'}]">{{ $t('nav.language.es') }}</span>
            <span @click="languageSelected = 'PT'" :class="[{'selected' : languageSelected == 'PT'}]">{{ $t('nav.language.pt') }}</span>
            <span @click="languageSelected = 'DE'" :class="[{'selected' : languageSelected == 'DE'}]">{{ $t('nav.language.de') }}</span>
            <span @click="languageSelected = 'FR'" :class="[{'selected' : languageSelected == 'FR'}]">{{ $t('nav.language.fr') }}</span>
            <span @click="languageSelected = 'HI'" :class="[{'selected' : languageSelected == 'HO'}]">{{ $t('nav.language.hi') }}</span>
          </div>
        </transition>
      </div>
      <hr class="s-hr-vertical-white s-hide-pc-xs s-hide">

      <a href="https://chrome.google.com/webstore/detail/seedr/abfimpkhacgimamjbiegeoponlepcbob"
         class="nav-link hvr-fade s-hide-pc-xs">{{ $t('nav.chrome-extension-link') }}</a>
      <a class="nav-link hvr-fade s-hide-mobile-l" href="https://www.seedr.cc/premium"><s-icon :icon-image="premiumSvgImg" img-class="icon" />{{ $t('nav.premium') }}</a>
      <a class="nav-link hvr-fade s-show-mobile-l" href="https://www.seedr.cc/premium"><s-icon :icon-image="premiumSvgImg" img-class="icon" /></a>

      <s-button @click="loginClicked" class="button-link button-nav login">{{loginText}}</s-button>

    </nav>
  </div>

<!--  <Teleport to="#modals">-->
    <transition name="t-backdrop">
      <section class="s-modal-backdrop-container" v-if="navActions === 'login'">
        <s-modal
              modal-type="login"
              @click-leave="navActions = ''"
              @click-close="navActions = ''"
              @click-confirm="navActions = ''"
          ></s-modal>
      </section>
    </transition>
<!--  </Teleport>-->
</template>

<script>

import SIcon from '../components/SIcon.vue'
import SButton from "../components/SButton.vue";
import SModal from "../components/SModal.vue";


import navLogoWhtImg from '/assets/images/nav_Logo_3_Wht.svg?url'
import navLogoIconWhtImg from '/assets/images/nav_Logo_Icon_3_Wht.svg?url'

import navV2Icon from '/assets/images/v2_icon.svg?url'
import navBtnImg from '/assets/images/Nav_Btn_SVG.svg?url'
import navBtnCloseImg from '/assets/images/nav_btn_close.svg?url'

import iconProductFilesImg from '/assets/images/icon_products_files.svg?url'
import iconProductCloudImg from '/assets/images/icon_products_cloud.svg?url'
import iconProductSearchImg from '/assets/images/icon_products_search_3.svg?url'

import logoMainImg from '/assets/images/Logo_main.svg?url'
import logoSvgImg from '/assets/images/Logo_SVG.svg?url'
import premiumSvgImg from '/assets/images/plan_premium_logo_SVG.svg?url'

import {usePageContext} from "vike-vue/usePageContext"

export default {
  name: 'Nav',
  components: {
    SModal,
    SButton,
    SIcon
  },
  props: {
    msg: String
  },
  data () {
    return {
      linksHidden: true,
      dropdownShown: false,
      languageSelected: 'EN',
      navActions: '',
      navLogoWhtImg: navLogoWhtImg,
      navLogoIconWhtImg: navLogoIconWhtImg,
      navV2Icon: navV2Icon,
      navBtnImg: navBtnImg,
      navBtnCloseImg: navBtnCloseImg,
      iconProductFilesImg: iconProductFilesImg,
      iconProductCloudImg: iconProductCloudImg,
      iconProductSearchImg: iconProductSearchImg,
      logoMainImg: logoMainImg,
      logoSvgImg: logoSvgImg,
      premiumSvgImg: premiumSvgImg
    }
  },
  computed: {
    pageContext() {
      return usePageContext()
    },
    isV2() {
      return import.meta.env.VITE_V2 === "1";
    },
    navHidden () {
      const hideNavPages = ['/payment', '/signup', '/alpha'] // page paths where the top nav is hidden
      return hideNavPages.some(page => this.pageContext.urlPathname.startsWith(page));
    },
    loginText () {
      if(typeof window !== 'undefined' && window.userId) {
        return this.$t('actions.go-to-app')
      } else {
        return this.$t('actions.login')
      }
    }
  },
  methods: {
    loginClicked() {
      if(window.userId) {
        if(this.isV2) {
          window.location.href = '/app/files/0'
        } else {
          window.location.href = '/files'
        }
      } else {
        this.navActions = 'login'
      }
    }
  },
  mounted: function() {
    window.seedrLoggedIn = function (data, type, windowName) {
      // redirect to /app
      if(import.meta.env.VITE_V2 === "1") {
        window.location.href = '/app/files/0'
      } else {
        window.location.href = '/files'
      }
    }

    window.seedrAccountCreated = function(data, type, windowName) {
      if (data.is_login == true) {
        if(import.meta.env.VITE_V2 === "1") {
          window.location.href = '/app/files/0'
        } else {
          window.location.href = '/files'
        }
      }

      if(windowName !== 'payment-signup-frame') {
        Swal.fire({
          title: 'Success!',
          text: 'Check your mailbox for an activation link',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      }
    }
    window.forgotPassword = function() {
      window.location.href = "/dynamic/lost_password"
    }
  }
}
</script>
