<template>
<!--  <transition name="t-fade">-->
    <!-- TODO: Insert back  <section class="s-modal-backdrop-container" when backdrop is implemented -->
     <section class="" id="modals">
     </section>
<!--  </transition>-->
</template>

<script>

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>




