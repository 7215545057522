// /layouts/useBaseLayout.js
import Swal from "sweetalert2"
import { onMounted } from 'vue'
import SModalsContainer from "/components/SModalsContainer.vue"

export function useBaseLayout() {
  onMounted(() => {
    window.Swal = Swal
  })

  return {
    SModalsContainer
  }
}
