import VueSweetalert2 from 'vue-sweetalert2';

// import { createPinia } from 'pinia'

import { createI18n } from 'vue-i18n'

// import Vue3Lottie from "vue3-lottie";
// import 'vue3-lottie/dist/style.css'

import en from "../translations/en.json"

export function onCreateApp(pageContext) {
  if (pageContext.isRenderingHead) return

  const {app} = pageContext

  // const store = createPinia()
  // app.use(store)

  app.use(VueSweetalert2)
    //.use(Vue3Lottie)

  const messages = {
    "en": en
  }
  const i18n = createI18n({
    locale: 'en',
    messages: messages,
    legacy: false,
    missing: (locale, key, vm) => {
      console.warn(`Missing translation for key: ${key}`);
      return key; // Return the key as the default value
    },
  })

  app.use(i18n)
  app.config.globalProperties.$t = (key, ...args) => i18n.global.t(key, args)
}
