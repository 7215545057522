<template>
  <div
    class="s-icon-comp"
    :class="[
      {'s-icon-comp-circle': circle},
      {'s-iconimage-comp-circle': circle && iconImage},
      {'s-icon-comp-roundsquare': roundsquare},
      {'s-tooltip': tooltip !== undefined && tooltip !== ''},
      {'s-tooltip-left': tooltipLeft},
      {'s-tooltip-right': tooltipRight},
      {'s-tooltip-top': tooltipTop},
      {'s-tooltip-white': tooltipWhite},
    ]"
    :data-tooltip="tooltip"
  >
    <i v-if="iconFont" class="s-icon" :class="iconFont"></i>
    <img v-if="iconImage" alt="icon" :class="imgClass" :src="iconImageUrl" />
  </div>
</template>

<script setup>
import { watch, ref, toRefs } from 'vue'
import {useDynamicImageLoader} from "../helper/useDynamicImageLoader";

const props = defineProps({
  imgClass: String,
  iconImage: String,
  iconFont: String,
  circle: Boolean,
  roundsquare: Boolean,
  tooltip: String,
  tooltipLeft: Boolean,
  tooltipRight: Boolean,
  tooltipTop: Boolean,
  tooltipWhite: Boolean,
})

const { iconImage } = toRefs(props)
const iconImageUrl = ref('')

useDynamicImageLoader(iconImage, iconImageUrl)
//
// watch(iconImage, async (newVal, oldVal) => {
//   if (newVal !== oldVal && newVal !== '') {
//     try {
//       const [iconImageName, iconImageExt] = newVal.split('.')
//       const iconPath = `/assets/images/${iconImageName}.svg?url`
//       const module = await import(iconPath)
//       iconImageUrl.value = module?.default || ''
//     } catch (error) {
//       console.error(error)
//       iconImageUrl.value = ''
//     }
//   }
// }, { immediate: true })
</script>
